import React, { useState, useEffect, useCallback } from 'react';
import { View } from 'react-native';
import clsx from 'clsx';
import { useAppState } from '../../state';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

// import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import Grid from '@material-ui/core/Grid';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import DividerWithText from '../DividerWithText/DividerWithText';
import QuestionOIcon from '../../icons/QuestionOIcon';
import ExclamationMarkOIcon from '../../icons/ExclamationMarkOIcon';
import SpeechBubbleIcon from '../../icons/SpeechBubbleIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';

import SessionHelper from '../SessionHelper/SessionHelper';
import BottomNavigation from '../BottomNavigation/BottomNavigation';
import ContactSupportDialog from '../ContactSupportDialog/ContactSupportDialog';
import AppFeedbackPopup from '../AppFeedbackPopup/AppFeedbackPopup';
import AppFeedbackSuccessDialog from '../AppFeedbackSuccessDialog/AppFeedbackSuccessDialog';

import { PartnerRating } from '../PartnerRating/PartnerRating';

import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Images
import defaultPartnerPhoto from '../../images/generic-trainerphoto.svg';

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '4px', //'0.5em',
  },
  gutterBottomBase: {
    'margin-bottom': '16px',
  },
  gutterBottomLarge: {
    'margin-bottom': '24px',
  },
  gutterBottomExtraExtraLarge: {
    'margin-bottom': '80px',
  },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  // submitButton: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },

  partnerProfilePictureContainer: {
    // width: '100%',
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    'border-radius': '10px',
    // border: `solid ${theme.palette.grey[400]}`,
    // '&.selected': {
    //   border: `solid ${theme.palette.primary.main}`,
    //   '& svg': {
    //     color: `${theme.palette.primary.main}`,
    //   },
    // },
  },

  partnerProfilePictureImg: {
    width: '132px',
    height: '132px',
    // position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    marginLeft: '16px',
    marginRight: '16px',
    marginBottom: '32px',
    'object-fit': 'cover',
    'border-radius': '16px',
    // border: `solid ${theme.palette.grey[400]}`,
    // '&:hover': {
    //   cursor: 'pointer',
    //   '& svg': {
    //     color: `${theme.palette.primary.main}`,
    //   },
    //   '& $thumbOverlay': {
    //     visibility: 'visible',
    //   },
    // },
    // '&.selected': {
    //   border: `solid ${theme.palette.primary.main}`,
    //   '& svg': {
    //     color: `${theme.palette.primary.main}`,
    //   },
    // },
  },

  greyColor: {
    color: theme.grey,
  },

  greyMainColor: {
    color: theme.greyMain,
  },

  redColor: {
    color: theme.red,
  },

  hidden: {
    display: 'none',
  },

  roundedTopContainer: {
    'margin-top': '-8px',
    'padding-top': '24px',
    'padding-left': '16px',
    'padding-right': '16px',
    'border-top-right-radius': '8px',
    'border-top-left-radius': '8px',
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'background-color': 'rgb(255, 255, 255)',
    position: 'relative',
  },

  linkLikeButton: {
    'text-decoration': 'underline',
  },

  logout: {
    cursor: 'pointer',
    // display: 'flex',
    background: 'transparent',
    border: '0',
    padding: '0',
  },
}));

// const gridItem = styled('div')({
//   height: "100%",
//   // margin: "8px",
//   // border: "1px solid red"
// });

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  // width: '1.5em',
  // marginRight: '0.3em',
});

export default function MainPage() {
  const classes = useStyles();
  const {
    // signIn,
    signOut,
    user,
    upcomingBookings,
    inProgressBookings,
    // isAuthReady,
    // getBookings,
    getUpcomingBookings,
    getInProgressBookings,
    getCompletedBookings,
    numberOfCompletedBookings,
    incomeInfo,
    getIncome,
  } = useAppState();
  const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  // const [authError, setAuthError] = useState<Error | null>(null);
  const [, setSessionInProgress] = useState<boolean>(false);
  const [contactSupportDialogOpen, setContactSupportDialogOpen] = useState<boolean>(false);
  const [appFeedbackPopupOpen, setAppFeedbackPopupOpen] = useState<boolean>(false);
  const [appFeedbackSuccessDialogOpen, setAppFeedbackSuccessDialogOpen] = useState<boolean>(false);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  const name = `${user?.firstName} ${user?.familyName}`;

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  const handleAppFeedbackPopupClose = useCallback(
    (isSuccess?: boolean) => {
      setAppFeedbackPopupOpen(false);

      if (isSuccess) {
        setAppFeedbackSuccessDialogOpen(true);
      }
    },
    [setAppFeedbackPopupOpen, setAppFeedbackSuccessDialogOpen]
  );

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  useEffect(() => {
    // getBookings();
    getUpcomingBookings({ region: user?.region });
    getInProgressBookings({ region: user?.region });
    getCompletedBookings({ region: user?.region });

    getIncome(user?.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user /* we can't put these deps here, because there will be infinite loading */]);

  const handleUpcomingSessionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      history.push({ pathname: '/sessions' });
    },
    [history]
  );

  const handleCompletedSessionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      history.push({ pathname: '/sessions/completed' });
    },
    [history]
  );

  // const handleSessionHelperClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const handleSessionHelperClick = () => {
    if (inProgressBookings && inProgressBookings.length) {
      setSessionInProgress(false);
    } else {
      setSessionInProgress(true);
    }
    // console.log('Box click');
    // console.log(event);
  };

  const handleTermsClick = useCallback(() => {
    if (user?.region === 'GB') {
      window.open('https://trubeapp.com/terms/partner-uk.html', '_blank');
    } else {
      window.open('https://trubeapp.com/terms/service-provider-agreement.html', '_blank');
    }
  }, [user]);

  const sessionHelperShowed = !!(
    (inProgressBookings && inProgressBookings.length) ||
    (upcomingBookings && upcomingBookings.length)
  );

  const showIncomeInfo = incomeInfo && (incomeInfo.monthValue != 0 || incomeInfo.yearValue != 0);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100vw' }}>
      <ScrollToTopOnMount />
      <Grid container wrap="nowrap" justifyContent="flex-start" style={{ marginTop: '32px' }}>
        <div className={classes.partnerProfilePictureContainer} onClick={() => {}}>
          <img
            className={clsx(classes.partnerProfilePictureImg, { selected: false })}
            src={
              user && user.photoWithBackgroundUrl
                ? user.photoWithBackgroundUrl
                : user && user.photoURL
                ? user.photoURL
                : defaultPartnerPhoto
            }
            alt={name}
          />
        </div>

        <div style={{ minWidth: 0 }}>
          <Typography variant="subtitle1" className={clsx(classes.gutterBottom, classes.greyColor)}>
            Your profile
          </Typography>

          <Typography variant="h2" style={{ marginBottom: '8px' }}>
            {user ? (
              <>
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{user.firstName}</div>
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{user.familyName}</div>
              </>
            ) : (
              <>
                <div> </div>
                <div> </div>
              </>
            )}
          </Typography>

          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '24px',
            }}
          >
            <PartnerRating
              partner={{
                partnerRatingRecap: {
                  average: user && user.partnerRating ? user.partnerRating : 5.0,
                  numberOfRatings: user && user.numberOfSessions ? user.numberOfSessions : 0,
                },
              }}
              className={''}
              trainerRatingRecapClassName={''}
              trainerNumberOfSessionsClassName={classes.hidden}
              onClick={() => {}}
            />

            <div style={{ marginLeft: '8px' }}>
              <Typography variant="subtitle1" className={classes.greyColor}>
                avg. rating
              </Typography>
            </div>
          </View>
        </div>
      </Grid>

      <View
        // color="white"
        // className={classes.roundedTopContainer}
        style={{
          flexGrow: 1,
          //   marginTop: '-8px',
          //   paddingTop: '24px',
          //   paddingLeft: '16px',
          //   paddingRight: '16px',
          //   borderTopRightRadius: 8,
          //   borderTopLeftRadius: 8,
          //   boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'rgb(255, 255, 255)',
          //   position: 'relative'
        }}
      >
        <div
          // color="white"
          className={classes.roundedTopContainer}
          style={{
            height: '100%',
            paddingBottom: sessionHelperShowed ? 170 : 88,
          }}
        >
          <Typography variant="subtitle1" className={clsx(classes.greyColor, classes.gutterBottomLarge)}>
            <DividerWithText>Session stats</DividerWithText>
          </Typography>

          <Grid container justifyContent="flex-start">
            <Grid container justifyContent="space-evenly">
              <View
                // display="flex"
                // justifyContent="center"
                // alignItems="center"
                // flexWrap="wrap"
                // flexDirection="column"
                style={{
                  justifyContent: 'center',
                  marginRight: '16px',
                  marginLeft: '16px',
                  // mb={3}
                  paddingBottom: '24px',
                }}
                // mr={2}
                // ml={2}
                // // mb={3}
                // pb={3}
                // // pt={3}
              >
                <button onClick={handleUpcomingSessionClick} className={classes.logout}>
                  <Typography
                    component="div"
                    variant="button"
                    className={clsx(classes.redColor)}
                    style={{ marginBottom: '16px', textAlign: 'center' }}
                  >
                    UPCOMING:
                  </Typography>
                  <Typography variant="h1" className={clsx(classes.redColor)}>
                    {upcomingBookings ? upcomingBookings!.length : 0}
                  </Typography>
                </button>
              </View>

              <View
                // display="flex"
                // justifyContent="center"
                // alignItems="center"
                // flexWrap="wrap"
                // flexDirection="column"
                style={{
                  justifyContent: 'center',
                  marginRight: '16px',
                  marginLeft: '16px',
                  // mb={3}
                  paddingBottom: '24px',
                }}
                // mr={2}
                // ml={2}
                // // mb={3}
                // pb={3}
                // // pt={3}
              >
                <button onClick={handleCompletedSessionClick} className={classes.logout}>
                  <Typography
                    component="div"
                    variant="button"
                    className={clsx(classes.greyColor)}
                    style={{ marginBottom: '16px', textAlign: 'center' }}
                  >
                    COMPLETED:
                  </Typography>
                  <Typography variant="h1" className={clsx(classes.greyColor)}>
                    {numberOfCompletedBookings}
                  </Typography>
                </button>
              </View>
            </Grid>
          </Grid>

          {showIncomeInfo ? (
            <Typography variant="subtitle1" className={clsx(classes.greyColor, classes.gutterBottomLarge)}>
              <DividerWithText>Your income</DividerWithText>
            </Typography>
          ) : null}

          <Grid container justifyContent="flex-start">
            {showIncomeInfo ? (
              <Grid container justifyContent="space-evenly">
                <View
                  // display="flex"
                  // justifyContent="center"
                  // alignItems="center"
                  // flexWrap="wrap"
                  // flexDirection="column"
                  style={{
                    justifyContent: 'center',
                    marginRight: '16px',
                    marginLeft: '16px',
                    // mb={3}
                    marginBottom: '16px',
                  }}
                  // mr={2}
                  // ml={2}
                  // // mb={3}
                  // pb={3}
                  // // pt={3}
                >
                  <Typography
                    component="div"
                    variant="button"
                    className={clsx(classes.greyColor, classes.gutterBottom)}
                    style={{ marginBottom: '16px', textAlign: 'center' }}
                  >
                    THIS MONTH:
                  </Typography>
                  <Typography
                    variant="h1"
                    className={clsx(classes.greyMainColor, classes.gutterBottom)}
                    style={{ textAlign: 'center' }}
                  >
                    {incomeInfo ? incomeInfo.symbol : '$'}
                    {incomeInfo ? incomeInfo.monthValue : '0'}
                  </Typography>
                </View>

                <View
                  // display="flex"
                  // justifyContent="center"
                  // alignItems="center"
                  // flexWrap="wrap"
                  // flexDirection="column"
                  style={{
                    justifyContent: 'center',
                    marginRight: '16px',
                    marginLeft: '16px',
                    // mb={3}
                    marginBottom: '16px',
                  }}
                  // mr={2}
                  // ml={2}
                  // // mb={3}
                  // pb={3}
                  // // pt={3}
                >
                  <Typography
                    component="div"
                    variant="button"
                    className={clsx(classes.greyColor, classes.gutterBottom)}
                    style={{ marginBottom: '16px', textAlign: 'center' }}
                  >
                    TOTAL:
                  </Typography>
                  <Typography
                    variant="h1"
                    className={clsx(classes.greyMainColor, classes.gutterBottom)}
                    style={{ textAlign: 'center' }}
                  >
                    {incomeInfo ? incomeInfo.symbol : '$'}
                    {incomeInfo ? incomeInfo.yearValue : '0'}
                  </Typography>
                </View>
              </Grid>
            ) : null}

            <Container maxWidth="sm" disableGutters>
              <List component="nav" aria-label="mailbox folders">
                <Divider />
                <ListItem
                  button
                  style={{ paddingLeft: '0', paddingRight: '0' }}
                  onClick={() => setContactSupportDialogOpen(true)}
                  // display="flex"
                  // alignItems="center"
                  // justifyContent="space-between"
                  // flex="1"
                >
                  <QuestionOIcon />
                  <ListItemText primary="Contact Support" style={{ marginLeft: '16px' }} />
                  <IconContainer className={classes.redColor}>
                    <ArrowRightIcon />
                  </IconContainer>
                </ListItem>
                <Divider />
                <ListItem button divider style={{ paddingLeft: '0', paddingRight: '0' }} onClick={handleTermsClick}>
                  <ExclamationMarkOIcon />
                  <ListItemText primary="Terms and Conditions" style={{ marginLeft: '16px' }} />

                  <IconContainer className={classes.redColor}>
                    <ArrowRightIcon />
                  </IconContainer>
                </ListItem>
                <ListItem
                  button
                  style={{ paddingLeft: '0', paddingRight: '0' }}
                  onClick={() => setAppFeedbackPopupOpen(true)}
                >
                  <SpeechBubbleIcon />
                  <ListItemText primary="App Feedback " style={{ marginLeft: '16px' }} />
                  <IconContainer className={classes.redColor}>
                    <ArrowRightIcon />
                  </IconContainer>
                </ListItem>
                <Divider light />
              </List>
            </Container>

            <div
              className={classes.gutterBottomExtraExtraLarge}
              style={{
                marginTop: '4px',
                textAlign: 'center',
                flex: '1',
              }}
            >
              <button className={classes.logout} onClick={() => signOut!()}>
                <Typography variant="subtitle2" className={clsx(classes.greyColor, classes.linkLikeButton)}>
                  Log out
                </Typography>
              </button>
            </div>
          </Grid>
        </div>
      </View>

      <ContactSupportDialog
        open={contactSupportDialogOpen}
        region={user?.region!}
        onClose={() => setContactSupportDialogOpen(false)}
      />

      <AppFeedbackPopup open={appFeedbackPopupOpen} onClose={handleAppFeedbackPopupClose} />

      <AppFeedbackSuccessDialog
        open={appFeedbackSuccessDialogOpen}
        onClose={() => setAppFeedbackSuccessDialogOpen(false)}
      />

      {sessionHelperShowed && (
        <SessionHelper
          inProgress={inProgressBookings}
          upcoming={upcomingBookings}
          region={user ? user.region! : 'US'}
          onClick={handleSessionHelperClick}
        />
      )}

      <BottomNavigation />

      {/*<form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between">
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-passcode">
              Email
            </InputLabel>
            <TextField
              id="input-email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
            />
            <InputLabel shrink htmlFor="input-passcode">
              Password
            </InputLabel>
            <TextField
              id="input-passcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!password.length}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Grid>
      </form>*/}
    </div>
  );
}
