import React from 'react';
import { styled /*, Theme*/ } from '@material-ui/core/styles';
// import { SafeAreaProvider } from 'react-native-safe-area-context';

import { create } from 'jss';
import { StylesProvider /*jssPreset*/ } from '@material-ui/core/styles';

// import jssPluginRuleValueFunction from 'jss-plugin-rule-value-function';

import jssPluginGlobal from 'jss-plugin-global';

import jssPluginNested from 'jss-plugin-nested';

import jssPluginCamelCase from 'jss-plugin-camel-case';

import jssPluginDefaultUnit from 'jss-plugin-default-unit';

// import jssPluginVendorPrefixer from 'jss-plugin-vendor-prefixer';

// import jssPluginPropsSort from 'jss-plugin-props-sort';

// import rtl from 'jss-rtl'

// import MenuBar from './components/MenuBar/MenuBar';
// import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
// import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
// import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
// import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
// import Room from './components/Room/Room';

import AppStateProvider /*, { useAppState }*/ from './state';
import SignUpStateProvider from './state/SignUpState';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper/ServiceWorkerWrapper';
import Notifications from './components/Notifications/Notifications';
// import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import LoginPage from './components/LoginPage/LoginPage';
import PasswordResetPage from './components/PasswordResetPage/PasswordResetPage';
import PasswordResetInstructionsSent from './components/LoginPage/PasswordResetInstructionsSent';
import PasswordResetNewPassword from './components/LoginPage/PasswordResetNewPassword';
import MainPage from './components/MainPage/MainPage';
import AvailabilityCalendarPage from './components/AvailabilityCalendarPage/AvailabilityCalendarPage';
import AreasPage from './components/AreasPage/AreasPage';
import SessionsPage from './components/SessionsPage/SessionsPage';
// import SessionDetails from './components/SessionDetails/SessionDetails';
import RateSession from './components/RateSession/RateSession';
// import RunningLatePopup from './components/RunningLatePopup/RunningLatePopup';
import AppFeedbackPopup from './components/AppFeedbackPopup/AppFeedbackPopup';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import useHeight from './hooks/useHeight/useHeight';
// import useRoomState from './hooks/useRoomState/useRoomState';

import 'regenerator-runtime/runtime.js';

const jss = create({
  plugins: [jssPluginGlobal(), jssPluginNested(), jssPluginCamelCase(), jssPluginDefaultUnit()],
});

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto auto',
  minHeight: '-webkit-fill-available',
});

// const Main = styled('main')(({ theme }: { theme: Theme }) => ({
//   overflow: 'hidden',
//   paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
//   background: 'black',
//   [theme.breakpoints.down('sm')]: {
//     paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
//   },
// }));

export default function App() {
  // const roomState = useRoomState();
  // const [registration, setRegistration] = React.useState();
  const [registration, setRegistration] = React.useState<ServiceWorkerRegistration | null>(null);

  const onSWRegister = React.useCallback((registration: ServiceWorkerRegistration) => {
    setRegistration(registration);
  }, []);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  // SafeAreaProvider
  return (
    <StylesProvider jss={jss}>
      <Container style={{ width: '100%', /*position: 'fixed', */ height: `${height}px` }}>
        <Router>
          <ServiceWorkerWrapper onSWRegister={onSWRegister} />
          <AppStateProvider>
            <Switch>
              <PrivateRoute exact path="/">
                {/*<VideoApp />*/}
                <MainPage />
              </PrivateRoute>
              {/*<PrivateRoute path="/room/:URLRoomName">
              <VideoApp />
            </PrivateRoute>*/}
              <PrivateRoute path="/coverage">
                <AreasPage />
              </PrivateRoute>
              <PrivateRoute path="/availability">
                <AvailabilityCalendarPage />
              </PrivateRoute>
              <PrivateRoute exact path="/sessions">
                <SessionsPage sessionsToShow={'upcoming'} />
              </PrivateRoute>
              <PrivateRoute exact path="/sessions/completed">
                <SessionsPage sessionsToShow={'completed'} />
              </PrivateRoute>
              <PrivateRoute path="/sessions/completed/:BookingId">
                <SessionsPage sessionsToShow={'completed'} />
              </PrivateRoute>
              <PrivateRoute path="/sessions/:BookingId">
                <SessionsPage sessionsToShow={'upcoming'} />
              </PrivateRoute>
              <Route exact path="/rate-session">
                <RateSession />
              </Route>
              {/*<Route exact path="/running-late">
              <RunningLatePopup open onClose={() => {}} onSubmit={() => {}} />
            </Route>*/}
              <Route exact path="/app-feedback">
                <AppFeedbackPopup open onClose={() => {}} />
              </Route>

              <Route exact path="/password-reset">
                <SignUpStateProvider>
                  <PasswordResetPage />
                </SignUpStateProvider>
              </Route>
              <Route exact path="/password-reset-instructions">
                <PasswordResetInstructionsSent />
              </Route>
              <Route exact path="/password-reset-new-password">
                <PasswordResetNewPassword />
              </Route>
              <Route path="/login">
                <LoginPage />
              </Route>
              <Redirect to="/" />
            </Switch>
            <Notifications registration={registration} />
          </AppStateProvider>
        </Router>
        {/*{roomState === 'disconnected' ? (
        <PreJoinScreens />
      ) : (
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </Main>
      )}*/}
      </Container>
    </StylesProvider>
  );
  // </SafeAreaProvider>
}
